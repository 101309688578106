
import { Input, Tag, Button } from 'antd';


function numberFormatter(number, decimal) {

  if (!number) {
    return 0;
  }

  if (isNaN(decimal)) {
    decimal = 2;
  } else {
    decimal = parseFloat(decimal);
  }

  number = parseFloat(number);
  const roundedNumber = Number(number.toFixed(decimal)); // Round to two decimal places
  let formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(decimal);
  formattedNumber = parseFloat(formattedNumber).toLocaleString();
  return formattedNumber;
}

function trailBalanceFormatter(value) {
  if (value < 0) {
    return <strong style={{ color: 'red' }}>{'(' + numberFormatter(-value) + ')'}</strong>;
  } else {
    return <strong style={{ color: 'green' }}>{numberFormatter(value)}</strong>;
  }
}


function roundedNumberFormatter(number) {
  if (!number) {
    return 0;
  }

  number = parseFloat(number);

  // Round to the nearest 1,000
  const roundedNumber = Math.round(number / 1000) * 1000;

  // Format the number with commas as thousands separators
  const formattedNumber = roundedNumber.toLocaleString();

  return formattedNumber;
}


function decimalFormatter(number, decimal) {
  number = parseFloat(number);
  decimal = parseFloat(decimal);
  const roundedNumber = Number(number.toFixed(decimal)); // Round to two decimal places
  let formattedNumber = roundedNumber % 1 === 0 ? roundedNumber.toFixed(0) : roundedNumber.toFixed(decimal);
  return formattedNumber;
}


const totalInFormatter = (e, record) => {
  if (!record.isDebit) {
    return <>{numberFormatter(e)}</>
  }
}

const totalOutFormatter = (e, record) => {
  if (record.isDebit) {
    return <>{numberFormatter(e)}</>
  }
}

const finalFormatter = (e, record) => {
  return (<>
    <Tag className='tag'>{record.finalUsd}$  {record.totalLbp > 0 ? '+ ' + numberFormatter(record.totalLbp, 2) + 'L' : ''}</Tag>
  </>)
}



const inputValueFormatter = (value) => {
  if (value === 0) return '0'; // Ensure 0 stays 0
  if (!value || isNaN(value)) return ''; // Prevent errors on invalid input
  return new Intl.NumberFormat('en-US').format(parseFloat(value));
};

const onChangeValueFormatter = (value) => {
  return value.replace(/,/g, ''); // Remove commas before storing
}



export {
  numberFormatter,
  totalInFormatter,
  totalOutFormatter,
  finalFormatter,
  decimalFormatter,
  roundedNumberFormatter,
  trailBalanceFormatter,
  inputValueFormatter,
  onChangeValueFormatter,
};