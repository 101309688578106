import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FcBusinessman, FcKey } from "react-icons/fc";
import GetData from '../init/getData';
import { Input, Radio, Space, Spin } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown, InputGroup } from 'react-bootstrap';
import Loader from '../components/loader/loader';
import Select from 'react-select';



function Session(props) {

  const { api, selectedCompany, selectedYear, setAccess, devSetting, expireyDate, userData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(false);

  const companyId = selectedCompany.id;
  const yearId = selectedYear.id;

  useEffect(() => {
    if (userData.id == 777) {
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const data = {
      companyId,
      userData,
      yearId,
    }
    let res = await api.get("/session/getSession", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
    } else {
      setSession(res.data.session)
    }
    setIsLoading(false);
  };



  return (
    <div>

      {isLoading &&
        <Loader />
      }

      {!isLoading &&
        <GetData
          api={api}
          selectedCompany={selectedCompany}
          selectedYear={selectedYear}
          setAccess={setAccess}
          devSetting={devSetting}
          expireyDate={expireyDate}
          userData={userData}
          session={session}
        />
      }
    </div>
  )

}



export default Session;