import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { DataContext } from '../init/getData';
import Loader from '../components/loader/loader';
import { Button, Table, Divider } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { dateTableFormatter } from '../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter, statusFormatter } from '../components/formatters/defFormatter';
import { numberFormatter } from '../components/formatters/numberFormatter';

const EndSessionModal = (props) => {

  const { api, userData, sessionId, rateToMainCurrency, session, mainCurrency,
    rateMainToSecondaryCurrency, companyId, yearId, defaultCurrency } = useContext(DataContext);
  const createdBy = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const { showModal, toggleModal, title, setAccess } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(true);
  const [summary, setSummary] = useState(true);
  const [closingBalanceInput, setClosingBalanceInput] = useState(0);

  const isUsingMainCurrency = defaultCurrency.id == mainCurrency.id ? true : false;

  const cashAccountId = userRole.cashAccountId;
  const varianceAccountId = userRole.varianceAccountId;

  const openingBalance =  session?.openingBalance 
  const secondaryOpeningBalance =  session?.secondaryOpeningBalance;

  const balance = summary?.totalIn - summary?.totalOut;
  const secondaryBalance = summary?.secondaryTotalIn - summary?.secondaryTotalOut;
  
  const totalBalance = balance + parseFloat(openingBalance);
  const secondaryTotalBalance = secondaryBalance + parseFloat(secondaryOpeningBalance);

  const closingBalance = isUsingMainCurrency ? closingBalanceInput : rateToMainCurrency(defaultCurrency, closingBalanceInput);
  const secondaryClosingBalance = isUsingMainCurrency ? rateMainToSecondaryCurrency(closingBalanceInput) : closingBalanceInput;

  let variance = totalBalance - closingBalance;
  let secondaryVariance = secondaryTotalBalance - secondaryClosingBalance;

  const isDebit = variance > 0 ? true : false;
  variance = variance < 0 ? -variance : variance;
  let debit = isUsingMainCurrency ? variance : secondaryVariance;
  let credit = isUsingMainCurrency ? variance : secondaryVariance;

  let totalDebit = rateToMainCurrency(defaultCurrency, debit);
  let totalCredit = rateToMainCurrency(defaultCurrency, credit);

  let secondaryTotalCredit = rateMainToSecondaryCurrency(totalDebit);
  let secondaryTotalDebit = rateMainToSecondaryCurrency(totalCredit);

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const fetchData = async () => {
    const data = {
      sessionId,
    }
    let res = await api.get("/session/view", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
    } else {
      setSummary(res?.data?.summary)
    }
    setIsLoading(false)
  };

  const journalVoucher = {
    createdBy: createdBy,
    sessionId: sessionId,
    companyId: companyId,
    yearId: yearId,
    accountId: null,
    date: today,
    type: 'Variance',
    debit: debit,
    credit: credit,
    totalDebit: totalDebit,
    totalCredit: totalCredit,
    secondaryTotalDebit: secondaryTotalDebit,
    secondaryTotalCredit: secondaryTotalCredit,
    details: '',
    paymentType: 'cash',
    isDebit: isDebit,
    currencyId: defaultCurrency.id,
    rate: defaultCurrency?.rate,

    branchId: userRole?.branchId,
  }


  const vDebit = isDebit ? debit : 0;
  const vCredit = !isDebit ? credit : 0;

  const vTotalDebit = rateToMainCurrency(defaultCurrency, vDebit);
  const vTotalCredit = rateToMainCurrency(defaultCurrency, vCredit);

  const vSecondaryTotalDebit = rateMainToSecondaryCurrency(vTotalDebit);
  const vSecondaryTotalCredit = rateMainToSecondaryCurrency(vTotalCredit);

  const transactions = [
    {
      type: 'Variance',
      accountId: varianceAccountId,
      isDebit: isDebit,
      debit: vDebit,
      credit: vCredit,
      totalDebit: vTotalDebit,
      totalCredit: vTotalCredit,
      secondaryTotalDebit: vSecondaryTotalDebit,
      secondaryTotalCredit: vSecondaryTotalCredit,
      rate: defaultCurrency.rate,
      currencyId: defaultCurrency.id,
    },
    
  ]

  const aDebit = !isDebit ? debit : 0;
  const aCredit = isDebit ? credit : 0;

  const aTotalDebit = rateToMainCurrency(defaultCurrency, aDebit);
  const aTotalCredit = rateToMainCurrency(defaultCurrency, aCredit);

  const aSecondaryTotalDebit = rateMainToSecondaryCurrency(aTotalDebit);
  const aSecondaryTotalCredit = rateMainToSecondaryCurrency(aTotalCredit);



  transactions.push({
    type: 'Account Payable',
    accountId: cashAccountId,
    isDebit: !isDebit,
    debit: aDebit,
    credit: aCredit,
    totalDebit: aTotalDebit,
    totalCredit: aTotalCredit,
    secondaryTotalDebit: aSecondaryTotalDebit,
    secondaryTotalCredit: aSecondaryTotalCredit,
    rate: defaultCurrency.rate,
    currencyId: defaultCurrency.id,
  },)


  const handleSubmit = async () => {
    if(!closingBalance){
      toast.error('please enter closing balance')
      return;
    }
    setLoadingButton(true);
    const data = {
      journalVoucher,
      transactions,
      sessionId,
      closingBalance,
      secondaryClosingBalance,
      balance: totalBalance,
      secondaryBalance: secondaryTotalBalance,
      variance,
      secondaryVariance,
    }
    let res = await api.post("/session/end", data);
    if (!res.data.status) {
      toast.error(res.data.message);

    } else {
      toast.success(res.data.message);
      setAccess(false);
    }
    setLoadingButton(false);
  };





  return (
    <Modal show={showModal} backdrop="static" size={'md'} keyboard={false} onHide={toggleModal}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }} >
      <Modal.Header style={{ background: "#eb5355" }}>
        <Modal.Title style={{ color: "white" }} >End Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Row>
          <Col>
            <Form.Label>Balance {defaultCurrency.code}</Form.Label>
            <Form.Control type="number" step="any"
              onChange={e => setClosingBalanceInput(e.target.value)}
              value={closingBalanceInput} />
          </Col>
        </Row>


        <div className="modal-footer" style={{ justifyContent: 'center' }}>
          <Button size="large" variant="filled" onClick={toggleModal}>Close</Button>
          <Button type="primary" size="large" onClick={handleSubmit} danger>End Session</Button>
        </div>

      </Modal.Body>
    </Modal>
  )
}


export default EndSessionModal;