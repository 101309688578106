import React, { useState, useEffect, useContext, createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import LeftMenu from '../menu/menu';
import { DataContext } from './getData';


export const PosCartContext = createContext();

const PosCartData = (props) => {


  const { api, userData, settingData, selectedCompany, selectedYear, currencyData, sessionId, defaultCurrency } = useContext(DataContext);

  const createdBy = parseInt(userData.id);
  const companyId = selectedCompany.id;
  const yearId = selectedYear.id;

  const userRole = userData?.role;
  const branchId = userRole?.branchId;
  const warehouseId = userRole?.warehouseId;

  const getCurrency = currencyData?.find(option => option.id === settingData.mainCurrencyId);
  const rate = parseFloat(getCurrency?.rate) || 0;

  const journalVoucher = {
    createdBy: createdBy,
    sessionId: sessionId,
    companyId: companyId,
    yearId: yearId,
    accountId: null,
    type: '',
    totalDebit: 0,
    totalCredit: 0,
    secondaryTotalDebit: 0,
    secondaryTotalCredit: 0,
    paymentType: 'cash',
    isDebit: false,
    isOrder: false,
    discountType: 'percentage',
    discountPercentage: 0,
    discountAmount: 0,
    currencyId: defaultCurrency.id,
    rate: rate,

    warehouseId: warehouseId,
    projectId: null,
    branchId: branchId,
  };


  const cart = [];
  const transactions = [];
  const isVat = true;


  const tab = { journalVoucher, transactions, cart, isVat };

  const [tabData, setTabData] = useState([tab, tab, tab, tab]);

  const setCart = (cart, selectedTab) => {

    let updatedCart = [...cart];

    setTabData((prevTabs) => {
      const updatedTabs = [...prevTabs];
      updatedTabs[selectedTab] = {
        ...updatedTabs[selectedTab],
        cart: updatedCart
      };
      return updatedTabs;
    });
  }

  const setJournalVoucher = (journalVoucher, selectedTab) => {

    let updatedJv = { ...journalVoucher };

    setTabData((prevTabs) => {
      const updatedTabs = [...prevTabs];
      updatedTabs[selectedTab] = {
        ...updatedTabs[selectedTab],
        journalVoucher: updatedJv
      };
      return updatedTabs;
    });
  }


  const resetJournalVoucher = (selectedTab) => {

    setTabData((prevTabs) => {
      const updatedTabs = [...prevTabs];
      updatedTabs[selectedTab] = {
        ...updatedTabs[selectedTab],
        journalVoucher: { ...journalVoucher },
        cart: []
      };
      return updatedTabs;
    });

  };



  return (
    <div>

      <PosCartContext.Provider
        value={{
          tabData,
          setTabData,
          setCart,
          setJournalVoucher,
          resetJournalVoucher,
        }}>
        <LeftMenu />
      </PosCartContext.Provider>
    </div>
  );
}
export default PosCartData;
